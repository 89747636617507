/* colors */
/*@blue:#415f8c;*/
/* PADDING */
/* TOOLBAR */
/*Toolbar colors*/
/*Toolbar button colors*/
/*Toolbar form elements*/
/*Toolbar dropdown menu*/
.altai-theme-admin .altai-list .row:hover {
  background-color: #f1f1f1;
}
.altai-theme-admin .altai-list .row:hover .avatar {
  background-color: none;
}
.altai-theme-admin .altai-list .row {
  display: flex;
  padding: 4px 20px 4px 20px;
  align-items: center;
  margin-right: 0;
}
.altai-theme-admin .altai-list .row .rowtext {
  flex: 1;
}
.altai-theme-admin .altai-list .row .rowstats {
  display: flex;
}
.altai-theme-admin .altai-list .row .rowstats div {
  margin-right: 10px;
}
.altai-theme-admin .altai-list .row .text h2,
.altai-theme-admin .altai-list .row .text h3 {
  margin: 0;
}
.altai-theme-admin .altai-list .row .text h2 {
  font-size: 13px;
  font-weight: lighter;
  letter-spacing: 1px;
}
.altai-theme-admin .altai-list .row .text h3 {
  font-size: 12px;
  font-weight: lighter;
  letter-spacing: 1px;
  color: #525252;
}
.altai-theme-admin .altai-list .row .avatar {
  width: 20px;
  height: 20px;
  background-color: none;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0068ff;
}
.altai-theme-admin .altai-list .search {
  display: flex;
  padding: 20px 20px 10px 20px;
  align-items: center;
}
.altai-theme-admin .altai-list .search .query {
  flex: 1;
  padding: 7px;
  border: 0;
  background-color: #fEFEFE;
  border-left: 4px solid #efefef;
}
.altai-theme-admin .altai-list .search .add {
  outline: none;
  margin-left: 10px;
  border-radius: 50%;
  border: 0;
  height: 30px;
  width: 30px;
  background-color: transparent;
  color: #0068FF;
  border: 2px solid #0068ff;
}
.altai-theme-admin .altai-list .header-filter-collection {
  padding: 10px 20px 10px 20px;
  display: flex;
  flex: 1;
}
.altai-theme-admin .altai-list .header-filter-collection .header-filter-section {
  display: flex;
  flex: 1;
}
.altai-theme-admin .altai-list .header-filter-collection .header-filter-section .header-filter-item {
  display: flex;
  flex: 1;
}
